import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе Forge Fitness
			</title>
			<meta name={"description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе Forge Fitness"} />
			<meta property={"og:description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:image"} content={"https://vintacores.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vintacores.com/img/8842248.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vintacores.com/img/8842248.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vintacores.com/img/8842248.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vintacores.com/img/8842248.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vintacores.com/img/8842248.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vintacores.com/img/8842248.png"} />
			<meta name={"msapplication-TileColor"} content={"https://vintacores.com/img/8842248.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://vintacores.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Про нас
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Наша історія
				</Text>
				<Text font="--base">
				Forge Fitness був заснований з простою метою: створити сприятливе середовище, де будь-хто може розпочати свій шлях у фітнесі та бачити, як він процвітає. Те, що почалося з пристрасті до здорового способу життя, переросло у процвітаючу спільноту, де кожен, від тих, хто вперше відвідує спортзал, до спортсменів, що змагаються, може знайти своє місце. Наш тренажерний зал побудований на вірі в те, що кожен заслуговує на доступ до першокласних фітнес-ресурсів та чуйного керівництва.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Наші тренери
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Серцем Forge Fitness є наші віддані своїй справі тренери. Кожен тренер має багатий багаж знань, пристрасть до фітнесу та прагнення допомогти членам клубу досягти їхніх цілей. Маючи сертифікати з різних дисциплін, наші тренери гарантують, що ви отримаєте кваліфіковану консультацію, структуроване тренування та мотиваційну підтримку, адаптовану до вашого рівня фізичної підготовки та прагнень.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Forge Fitness - це не просто ще один тренажерний зал. Нас впізнають за:
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Чим вирізняється Forge Fitness
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Інноваційні програми: Від високоінтенсивних тренувань до сеансів усвідомленої йоги - наші програми розроблені з урахуванням усіх аспектів фітнесу.
Дух спільноти: Побудуйте міцні стосунки в громаді, яка святкує успіхи один одного.
Першокласне обладнання: Використовуйте наше найсучасніше обладнання, яке встановлює стандарти для сучасних фітнес-залів.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Images-22">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				grid-gap="20px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				sm-width="100%"
			>
				<Image src="https://vintacores.com/img/7.jpg" display="block" width="100%" />
			</Box>
			<Box
				width="40%"
				lg-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-align-items="flex-end"
			>
				<Text
					color="--darkL2"
					margin="100px 0px 50px 10px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-margin="0px 0px 20px 0px"
					lg-font="--headline3"
					sm-margin="16px 0px 20px 0px"
					text-transform="uppercase"
					letter-spacing="2px"
				>
					Створіть свою фітнес-історію з нами

				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					lg-max-width="540px"
					lg-width="100%"
				>
					У Forge Fitness ваш фітнес-подорож є нашим пріоритетом. Ми віримо у створення персоналізованих шляхів, які відповідають вашому стилю життя та цілям. Приєднуйтесь до нас, і давайте разом створимо історію сили, наполегливості та трансформації.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});